import styles from './ClientUserListItem.module.css'
import React from 'react';
// import IconEdit from './IconEdit';
import IconDelete from '../App/Icon/IconDelete';

export default function ClientUserListItem({ user, navigate, companyId }) {
	return <div className={`${styles.AccountantListItem}`}>
		<div className={`${styles.FullNameWrapperWrapper}`}>
			<span className={`${styles.FullNameWrapperWrapper_FullName}`}>
				{user.firstName} {user.lastName}
			</span>
		</div>
		<div className={`${styles.RoleWrapperWrapper}`}>
			<span className={`${styles.RoleWrapperWrapper_Role}`}>
				{user.email}
			</span>
		</div>
		<div className={`${styles.IconWrapper}`}>
			{/* <IconEdit className={`${styles.IconEdit}`} /> */}
			<div className={`deleteUser-${user.firstName}-${user.lastName}`} onClick={() => navigate(`/company/${companyId}/delete-user/${user.id}`)}>
				<IconDelete className={`${styles.IconDelete}`} />
			</div>
		</div>
	</div>
}
