import styles from './ClientCompanyInfoModalAddUser.module.css'
import H2 from '../../TextStyles/H2';
import FormInputText from '../../Symbols/App/Form/FormInputText';
import FormInputCheckbox from '../../Symbols/App/Form/FormInputCheckbox';
import ButtonPrimary from '../../Symbols/App/Button/ButtonPrimary';
import React, {useState}from 'react';
import Modal from '../../modal/Modal';
import { httpPost } from '../../modules/backend';
import useForm from '../../hooks/useForm';

export default function ClientCompanyInfoModalAddUser({navigate, onSuccess, companyId, ...others}) {
	const [error, setError] = useState(false);
	const { values, handleChange, handleSubmit } = useForm(onFormSubmit);

	async function onFormSubmit() {
		values.clientCompanyId = companyId
		await httpPost('client-users', values)
		.then(async function (response) {
			if(response.data.success){
				onSuccess();
			}else{
				setError("Email is in use by another user.");
			}
		})
		.catch(function () {
			setError("Could not save user.");
		});
	}


	return <Modal {...others}>
	<div className={`${styles.ModalTitle}`}>
		<H2 className={`${styles.AddANewUser_H2}`}>Add a new user</H2>
	</div>
	<form className={`${styles.FormWrapper}`} onSubmit={handleSubmit}>
		<div className={`${styles.InputWrapper}`}>
			<FormInputText label="First name" name="firstName" onChange={handleChange} value={values.firstName || ''} required/>
		</div>
		<div className={`${styles.InputWrapper}`}>
			<FormInputText label="Last name" name="lastName" onChange={handleChange} value={values.lastName || ''} required/>
		</div>
		<div className={`${styles.InputWrapper}`}>
			<FormInputText label="Email address" name="email" onChange={handleChange} value={values.email || ''} type="email" required/>
		</div>
		
		<div className={`${styles.ButtonWrapper}`}>
			<ButtonPrimary className={`${styles.ButtonPrimary}`} text="Add user" type="submit" name="submit"/>
		</div>
		{error &&
			<span className={`${styles.Error}`}>{error}</span>
		}
	</form>
</Modal>
}
