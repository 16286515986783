import styles from './SelectDayOfMonth.module.css'
import React, {useState, useEffect}from 'react';

export default function SelectDayOfMonth({onSelect, dayOfMonth}) {
	const [value, setValue] = useState();

	function onClickHandeler(value){
		setValue(value)
		onSelect(value)
	}

	useEffect(() => {
		setValue(dayOfMonth)
	}, [dayOfMonth]);

	return <div className={`${styles.SelectDayOfMonth}`}>
			<button type="button" className={`${value === 1 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(1)}>1</button>
			<button type="button" className={`${value === 2 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(2)}>2</button>
			<button type="button" className={`${value === 3 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(3)}>3</button>
			<button type="button" className={`${value === 4 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(4)}>4</button>
			<button type="button" className={`${value === 5 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(5)}>5</button>
			<button type="button" className={`${value === 6 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(6)}>6</button>
			<button type="button" className={`${value === 7 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(7)}>7</button>
			<button type="button" className={`${value === 8 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(8)}>8</button>
			<button type="button" className={`${value === 9 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(9)}>9</button>
			<button type="button" className={`${value === 10 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(10)}>10</button>
			<button type="button" className={`${value === 11 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(11)}>11</button>
			<button type="button" className={`${value === 12 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(12)}>12</button>
			<button type="button" className={`${value === 13 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(13)}>13</button>
			<button type="button" className={`${value === 14 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(14)}>14</button>
			<button type="button" className={`${value === 15 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(15)}>15</button>
			<button type="button" className={`${value === 16 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(16)}>16</button>
			<button type="button" className={`${value === 17 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(17)}>17</button>
			<button type="button" className={`${value === 18 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(18)}>18</button>
			<button type="button" className={`${value === 19 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(19)}>19</button>
			<button type="button" className={`${value === 20 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(20)}>20</button>
			<button type="button" className={`${value === 21 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(21)}>21</button>
			<button type="button" className={`${value === 22 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(22)}>22</button>
			<button type="button" className={`${value === 23 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(23)}>23</button>
			<button type="button" className={`${value === 24 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(24)}>24</button>
			<button type="button" className={`${value === 25 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(25)}>25</button>
			<button type="button" className={`${value === 26 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(26)}>26</button>
			<button type="button" className={`${value === 27 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(27)}>27</button>
			<button type="button" className={`${value === 28 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(28)}>28</button>
	</div>
}
