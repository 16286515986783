import styles from './FormInputTextReadonlyWithLabel.module.css'
import React from 'react';

export default function FormInputTextReadonlyWithLabel({className, value, label}) {
	return <div >
		<label className={`${styles.InputTextReadOnly_Label}`}>
			{label}
		</label>
		<div className={`${styles.InputTextReadOnly} ${className ? className : ''}`}>
			<span className={`${styles.InputTextReadOnly_Text}`}>
				{value}
			</span>
		</div>
	</div>
}
