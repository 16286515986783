import React from 'reactn';
import './App.css';
import {Router} from '@reach/router';
import LoginCallback from './pages/App/LoginCallback.js';
import Secure from './pages/App/Secure.js';
import Auth from './modules/auth/auth';
import 'react-app-polyfill/ie11';
import 'core-js';
import { UserProvider } from './context/UserProvider';
import HeaderLoggedIn from './Symbols/App/Header/HeaderLoggedIn';
import AdminSettings from './pages/Admin Settings/AdminSettings';
import Dashboard from './pages/Dashboard/Dashboard'
import ClientCompanyInfo from './pages/Client Company/ClientCompanyInfo';
import AddClientCompany from './pages/Client Company/AddClientCompany';
import EditClientCompany from './pages/Client Company/EditClientCompany';
import MassAddEmployeesToPayrollSchedule from './pages/Client Company/MassAddEmployeesToPayrollSchedule';
import PayrollSummary from './pages/Payroll/PayrollSummary';

const NotFound = () => <span>Sorry, page not found</span>;

function App() {

    let auth = new Auth();

    return (
        <UserProvider auth={auth}>
            <HeaderLoggedIn auth={auth}/>
            <Router>
                <NotFound default/>
                <LoginCallback path='/callback' auth={auth}/>
                <Secure path='/' auth={auth}>
                    <Dashboard path='/'/>
                    <AdminSettings path='/settings/*'/>
                    <ClientCompanyInfo path='/company/:companyId/*' />
                    <AddClientCompany path='/add-company/*'/>
                    <EditClientCompany path='/edit-company/:companyId/*'/>
                    <MassAddEmployeesToPayrollSchedule path='/company/:companyId/mass-add-employees-to-schedule/:paymentScheduleId' />
                    <PayrollSummary path="/payrollSummary/:payrollId" />
                </Secure>
            </Router>
        </UserProvider>
    );
}

export default App;