import styles from './PayrollSubmissions.module.css'
import PayrollSubmissionItem from './PayrollSubmissionItem';
import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { httpGet } from '../../modules/backend';
import InfiniteScroll from '../App/Scroll/InfiniteScroll'
import LoadingModal from '../App/Loading/LoadingModal';

export default function PayrollSubmissions({clientCompanyId}) {
	const [payrollSubmissions, setPayrollSubmissions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [error, setError] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [hasPendingPayrollSubmissions, setHasPendingPayrollSubmissions] = useState(false);
	const [paymentDates, setPaymentDates] = useState();
	const [showModal, setShowModal] = useState(false);
	const [showLoadingModal, setShowLoadingModal] = useState(false);

	// This is to prevent the button visibly changing while the page is loading
	const [apiCallComplete, setApiCallComplete] = useState(false)

	async function getPayrollSubmissions(refreshList = false) {
		setLoading(true);
		var skip = payrollSubmissions.length;
		var take = 10;
		if (refreshList) {
			skip = 0;
			take = payrollSubmissions.length;
		}
		await httpGet(`payroll-submissions/by-company-id?ClientCompanyId=${clientCompanyId}&Skip=${skip}&Take=${take}`)
			.then(async function (result) {
				//Order is end date desc from backend, so put the newly loaded payroll submissions first
				const allPayrollSubmissions = refreshList ? result.payrollSubmissions : [ ...payrollSubmissions, ...result.payrollSubmissions];
				setPayrollSubmissions(allPayrollSubmissions);
				setApiCallComplete(true);
				setPaymentDates(result.availableScheduleDatesForNewPayroll)

				if (result.payrollSubmissions.length === 0) {
					setHasMore(false);
				}
				setLoading(false);
			}).catch(function (error) {
				setErrorFromResponse(error)
			});
	}
	

	useEffect(() => {
		getPayrollSubmissions();
	}, []);


	function openSummary(id){
		navigate(`/payrollSummary/${id}`);
	}

	function setErrorFromResponse(error) {
		setIsError(true);
		setError(error.response.data);
		setLoading(false);
	}

	return <div className={`${styles.Content}`}>
		<div>
			{showLoadingModal &&
			<LoadingModal />
			}			
			<div className={`row`}>
				<div className={`columns small-12`}>
					<div>
						<div className={`${styles.SearchResultsHeader} ${styles.Row_FlexWrapper}`}>
							<div className={`${styles.PayPeriodWrapperWrapper_FlexWrapper}`}>
								<div className={`${styles.PayPeriodWrapperWrapper}`}>
									<span className={`${styles.PayPeriodWrapperWrapper_PayPeriod}`}>Pay period</span>
								</div>
							</div>
							<div className={`${styles.PaymentDateWrapperWrapper_FlexWrapper}`}>
								<div className={`${styles.PaymentDateWrapperWrapper}`}>
									<span className={`${styles.PaymentDateWrapperWrapper_PaymentDate}`}>Payment date</span>
								</div>
							</div>
							<div className={`${styles.StatusWrapperWrapper_FlexWrapper}`}>
								<div className={`${styles.StatusWrapperWrapper}`}>
									<span className={`${styles.StatusWrapperWrapper_Status}`}>
										Status
									</span>
								</div>
							</div>
						</div>

						<div id="payrollSubmitions-wrapper">
							{payrollSubmissions &&
								payrollSubmissions.map((payroll, i) =>
									<PayrollSubmissionItem key={i} className={`${styles.ResultsRow}`} payroll={payroll} />
								)
							}
						</div>
					</div>
					<InfiniteScroll loadFunction={getPayrollSubmissions} error={isError} isLoading={loading} hasMore={hasMore} />
				</div>
			</div>
		</div>
	</div>
}
