import { useState } from 'react';

const useForm = (callback) => {

  const [values, setValues] = useState({});
  
  const handleSubmit = (event) => {
    if (event) event.preventDefault();
      callback();
  };

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
  };

  const handleChangeById = (event, id) => {
    event.persist();
    var edited = values.map(
			el => el.id === id? { ...el, [event.target.name]: event.target.value }: el
		)
    
    setValues(edited);
  };

  const handleCheckboxChange = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.checked }));
  }

  const setInitialValues = (values) => {
    setValues(values);
  };

  return {
    handleChange,
    handleChangeById,
    handleCheckboxChange,
    handleSubmit,
    values,
    setInitialValues
  }
};

export default useForm;