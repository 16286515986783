import styles from './PayrollSummaryListItem.module.css'
import React, { useEffect, useState } from 'react';
import {navigate} from '@reach/router';
import IconEdit from '../App/Icon/IconEdit';
import IconClose from '../App/Icon/IconClose';
import ButtonPrimary from '../App/Button/ButtonPrimary';
import FormInputText from '../App/Form/FormInputText';
import useForm from '../../hooks/useForm';
import { httpPut, httpDelete } from '../../modules/backend';
import IconDelete from "../App/Icon/IconDelete";
import DeletePayrollElementModal from "./DeletePayrollElementModal";
import Tooltip from 'react-tooltip-lite';
import moment from 'moment';

export default function PayrollSummaryListItem({data, showEdit, hasBeenDeleted, payrollScheduleId, setMasterShowEdit, reloadParentFunction}) {
	const { values, handleChangeById, handleSubmit, setInitialValues } = useForm(onFormSubmit);
	const [editMode, setEditMode] = useState(false);
	const [deletingElementId, setDeletingElementId] = useState('');
	const [showElementDeletionModal, setShowElementDeletionModal] = useState(false);
	const [error, setError] = useState(false);

	function numberWithCommas(x) {
		var numbers = x.toString().split('.');
		var output = (numbers[0].length>0 ? numbers[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0")+"."+(numbers[1].length>1 ? numbers[1]: "0000");
		return output;
	}

	function save(){
		onFormSubmit();
	}

	function edit(){
		setMasterShowEdit(false);
		setEditMode(true);
	}

	async function onFormSubmit(){
		await httpPut('employee-payrolls/payment-deductions', { employeePayrollId : data.id, paymentDeductionList : values})
		.then(async function (response) {
			//reload parent
			reloadParentFunction()
		})
		.catch(function () {
			setError('Error saving payroll please try again')
		});

	}

	function cancelEdit(){
		setInitialValues(data.paymentDeductions)
		setMasterShowEdit(true);
		setEditMode(false);
	}

	useEffect(() => {
        setInitialValues(data.paymentDeductions)
    }, [])

	async function removeElement(id, deleted) {
		setDeletingElementId(id);
		if(deleted) {
			setShowElementDeletionModal(true);
		}
	}

	function onElementDeleted() {
		setShowElementDeletionModal(false);
		reloadParentFunction();
	}

	function onDeletionCancelled() {
		setShowElementDeletionModal(false);
	}

	return <>
		{showElementDeletionModal &&
			<DeletePayrollElementModal className={`${styles.DeleteModal}`} navigate={navigate} onClose={onDeletionCancelled} onSuccess={onElementDeleted} elementId={deletingElementId}
								   payrollScheduleId={payrollScheduleId}/>
		}
		{data &&
			<div className={`${styles.ItemWrapper}`}>
				<div className={`row collapse ${styles.DetailsWrapper}`}>
					<div className={`columns large-3 medium-12`}>
						<p className={`${styles.Name}`} >{data.name}</p>
						<p className={`${styles.NiNumber}`}>{data.ni}</p>
						{data.department!= null && data.department!= "" &&
							<p className={`${styles.NiNumber}`}>Department: {data.department}</p>
						}
						<p className={`${styles.NiNumber}`}>Works number: {data.worksNumber}</p>
					</div>
					<div className={`columns large-2 medium-12 ${styles.AdditionalWrapper}`}>
						{data.memo && data.memo != "" &&
							<Tooltip content={data.memo} className={`${styles.AdditionalItem}`}>
								<p className={`${styles.AdditionalItemText}`}>Memo</p>
							</Tooltip>
						}
						{data.smp && data.smp != "" &&
							<Tooltip content={
								<p>
									Medical evidence received: {(data.smpMedicalEvidenceReceived ? "Yes" : "No")}<br/>
									Last day of work: {(data.smpLastDayOfWork? moment(data.smpLastDayOfWork).format("DD/MM/YYYY") : "Not Set")} </p>
								} className={`${styles.AdditionalItem}`}>			
								<p className={`${styles.AdditionalItemText}`}>SMP</p>
							</Tooltip>
						}
						{data.spp && data.spp != "" &&
							<Tooltip content={
								<p>
									Declaration received: {(data.sppDeclarationReceived ? "Yes" : "No")} <br/>
									Last day of work: {(data.sppLastDayOfWork ? moment(data.sppLastDayOfWork).format("DD/MM/YYYY") : "Not Set")}</p>
								} className={`${styles.AdditionalItem}`}>			
								<p className={`${styles.AdditionalItemText}`}>SPP</p>
							</Tooltip>
						}
						{data.statutorySickPay && data.statutorySickPay.length > 0 &&
							<Tooltip content={
								<ul className={`${styles.AdditionalItemsList}`}>
									{data.statutorySickPay.map((item, i) =>
										<li key={i}>{moment(item.startDay).format("DD/MM/YYYY")} - {moment(item.lastDay).format("DD/MM/YYYY")}</li>)
									}
								</ul>
								} className={`${styles.AdditionalItem}`}>			
								<p className={`${styles.AdditionalItemText}`}>SSP</p>
							</Tooltip>
						}
						{data.documentFileNames && data.documentFileNames.length > 0 &&
							<Tooltip content={
								<ul className={`${styles.AdditionalItemsList}`}>
									{data.documentFileNames.map((doc, i) => 
										<li key={i}>{doc}</li>)}
								</ul>
							} className={`${styles.AdditionalItem}`}>			
								<p className={`${styles.AdditionalItemText}`}>Documents</p>
							</Tooltip>
						}
					</div>
					<div className={`columns large-5 medium-12 end `}>
						<form onSubmit={handleSubmit}>
							{data.paymentDeductions &&
									data.paymentDeductions.map((item, i) =>
										<div key={i} className={`row ${styles.PaymentDeductionWrapper} cy-payment-deductio-wrapper`}>
											<p className={`columns large-5 medium-4 small-6 ${styles.PaymentDeductionCol}`}>{item.payment === true? <span className={`${styles.Plus}`}>+</span> : <span className={`${styles.Negative}`}>-</span>}{item.title}</p>
											{editMode &&
												<>
													<div className={`columns large-2 medium-1 small-2 `}>
														<FormInputText className={`${styles.Input}`} name="qty" onChange={(event) => handleChangeById(event, item.id)} value={values[i].qty || ''} type="number" required/>
													</div>
													<div className={`columns large-5 medium-4 small-4 end  ${styles.PaymentDeductionAmount}`}>
														<FormInputText className={`${styles.Input} ${styles.InputRightAlign}`} name="amount" onChange={(event) => handleChangeById(event, item.id)} value={values[i].amount || ''} type="number" required />
													</div>
												</>
											}
											{!editMode &&
												<>
													<p className={`columns large-2 medium-1 small-2 `}>{item.qty}</p>
													<p className={`columns large-4 medium-4 small-3  ${styles.PaymentDeductionAmount}`}>£{numberWithCommas(item.amount)}</p>
												</>
											}
											<div className={`columns small-1 end ${styles.NoPadding}`}  onClick={() => removeElement(item.id, item.deleted)}>
											{
												item.deleted &&
													<IconDelete className={`${styles.IconDelete}`}/>
											}
											</div>
										</div>
									)
								}
						</form>
					</div>
				</div>
				<div className={`row ${styles.TotalWrapper} collapse`}>
					<div className={`columns large-8 medium-12`}>
						{error &&
							<p className={`${styles.Error}`}>{error}</p> 
						}
					</div>
					<div className={`columns large-4 medium-12`}>
						<p><b>Total payments:</b> £{numberWithCommas(data.totalPayments)} <span className={`${styles.TotalsPlus}`}>+</span></p>
						<p><b>Total deductions:</b> £{numberWithCommas(data.totalDeductions)} <span className={`${styles.TotalsNegative}`}>-</span></p>
					</div>
				</div>
				{showEdit && data.paymentDeductions.length > 0 &&
					<div className={`${styles.EditWrapper}`}>
					{!editMode &&
						<button onClick={edit} name="edit">
							<IconEdit />
						</button>
					}
					</div>
				}
				{editMode &&
					<>
						<div className={`${styles.EditWrapper}`}>
							<button onClick={cancelEdit} name="close">
								<IconClose />
							</button>
						</div>
					</>
				}
				<div className={editMode === true ? `${styles.SaveWrapper} ${styles.SaveActive}` : `${styles.SaveWrapper}` }>
					<ButtonPrimary className={`${styles.SaveBtn}`} onClick={save} text="Save" name="save"/>
				</div>
			</div>
		}
	</>
}
