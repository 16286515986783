import React from 'react';
import styles from './InfoToolTip.module.css'
import Tooltip from 'react-tooltip-lite';
import IconInfo from '../Icon/IconInfo';
import ValidationIcons from './ValidationIcons'

export default function InfoToolTip({className,text, valid}) {
	return <Tooltip className={`${className ? className : ''}`} content={text} zIndex={3000}>
        <div className={`${styles.InfoToolTipWrapper}`}>
            <ValidationIcons valid={valid}/>
            {valid === undefined &&
                <IconInfo/>
            }
            
        </div>
</Tooltip>
}
