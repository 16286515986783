import styles from './AdminSettingsModalDeleteUser.module.css'
import H2 from '../../TextStyles/H2';
import ButtonDestructive from '../../Symbols/App/Button/ButtonDestructive';
import ButtonNoStyle from '../../Symbols/App/Button/ButtonNoStyle';
import React, {useState} from 'react';
import Modal from '../../modal/Modal';
import { httpDelete } from '../../modules/backend';

export default function AdminSettingsModalDeleteUser({navigate, onSuccess, accountantId, ...others}) {
	const [error, setError] = useState(false);

	async function deleteAccountant(){
		await httpDelete('accountants', {id:accountantId})
		.then(async function () {
			onSuccess();
		})
		.catch(function () {
			setError("Could not save user.");
		});
	}
	
	return <Modal {...others}>
		<div className={`${styles.ModalTitle}`}>
			<H2 className={`${styles.DeleteUser_H2}`}>Delete Accountant</H2>
		</div>
		<div>
			<H2 className={`${styles.AreYouSureYouWan_H2}`}>Are you sure you want to <b>delete</b> this accountant?</H2>
		</div>
		<div className={`${styles.WrapperWithPadding}`}>
			<ButtonDestructive className={`${styles.ButtonDestructive}`} text="Delete" onClick={deleteAccountant} name="delete"/>
		</div>
		{error &&
				<span className={`${styles.Error}`}>{error}</span>
			}
		<div className={`${styles.Wrapper}`}>
			<ButtonNoStyle className={`${styles.ButtonDestructive}`} text="Cancel" onClick={() => navigate('/')} type="button"/>
		</div>
	</Modal>
}
