import styles from './Dashboard.module.css'
import HeaderLoggedIn from '../../Symbols/App/Header/HeaderLoggedIn';
import SeachInput from '../../Symbols/App/Search/SeachInput';
import CompanyListItem from '../../Symbols/Client/CompanyListItem';
import React, {useEffect, useState} from 'react';
import { useUser } from '../../context/UserProvider';
import { httpGet } from '../../modules/backend';
import ButtonGhost from '../../Symbols/App/Button/ButtonGhost';
import { Router } from '@reach/router';

export default function Dashboard({navigate}) {
	const [companies, setCompanies] = useState([]);
	const { user } = useUser();

	async function getCompanies(searchTerm) {
		var result = await httpGet(`client-companies/by-accountancy-id?AccountancyId=${user.accountancyId}&SearchTerm=${searchTerm}`);
		setCompanies(result.companies);
	}

	useEffect(() => {
		getCompanies("");
	}, []);

	function search(searchString){
		getCompanies(searchString);
	}

	function clearSearch(){
		getCompanies("");
	}

	function refreshPage() {
		getCompanies("");
	}


	return <div>
		<div className={`${styles.Content}`}>
			<div>
				<div className={`row`}>
					<div className={`columns medium-8 medium-offset-2 small-10 small-offset-1`}>
					<div className={`${styles.SettingTitleWrapper}`}>
							<div className={`${styles.TitleWrapperWrapper}`}>
								<span className={`${styles.TitleWrapperWrapper_Title}`}>Dashboard</span>
							</div>
							<div className={`${styles.ButtonGhostWrapperWrapper}`}>
								<ButtonGhost name="add-company" className={`${styles.ButtonGhost}`} text="Add company" onClick={() => navigate(`/add-company`)} />
							</div>
						</div>
						<div className={`${styles.Row} row`}>
							<div className={`columns small-12 text-right`}>
								<div className={`${styles.SearchWrapperWrapper}`}>
									<SeachInput label="Search" name="search" search={search} clearSearch={clearSearch}/>
								</div>
							</div>
						</div>
						<div className={`row`}>
							<div className={`columns small-12`}>
								<div className={`${styles.SearchResultsHeader} row text-right`}>
									<div className={`columns small-12`}>
										<div className={`${styles.NameWrapperWrapper}`}>
											<span className={`${styles.NameWrapperWrapper_Name}`}>
												Name
											</span>
										</div>
									</div>
								</div>
								<div id="companies">
								{companies && 
									companies.map((company, i) =>
										<CompanyListItem key={i} className={`${styles.SearchResult}`} company={company} navigate={navigate}/>
									)
								}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}
