import styles from './AccountantListItem.module.css'
import React from 'react';
// import IconEdit from './IconEdit';
import IconDelete from '../App/Icon/IconDelete';

export default function AccountantListItem({ accountant, navigate, clientAdmin = false }) {

	function naviagteToDelete(){
		if(clientAdmin === true){
			navigate(`/settings/delete-client-admin/${accountant.id}`)
		}else{
			navigate(`/settings/delete-accountant/${accountant.id}`)
		}
	}
	return <div className={`${styles.AccountantListItem}`}>
		<div className={`${styles.FullNameWrapperWrapper}`}>
			<span className={`${styles.FullNameWrapperWrapper_FullName}`}>
				{accountant.firstName} {accountant.lastName}
			</span>
		</div>
		<div className={`${styles.RoleWrapperWrapper}`}>
			<span className={`${styles.RoleWrapperWrapper_Role}`}>
				{accountant.email}
			</span>
		</div>
		<div className={`${styles.IconWrapper}`}>
			{/* <IconEdit className={`${styles.IconEdit}`} /> */}
			<div className={`deleteAccountant-${accountant.firstName}-${accountant.lastName}`} onClick={naviagteToDelete}>
				<IconDelete className={`${styles.IconDelete}`} />
			</div>
		</div>
	</div>
}
