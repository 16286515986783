import { httpGet } from '../modules/backend';
import React, { useState, useEffect, useContext } from 'react';

const UserContext = React.createContext();

function UserProvider(props) {

    const refreshUser = async function () {
        const user = await httpGet('accountants');
        setUser(user);
    }

    const [user, setUser] = useState();

    useEffect(() => {

        async function sync() {       
            await refreshUser();
        }

        if (props.auth.isAuthenticated()) {
            sync();
        }

    }, []);

    return <UserContext.Provider value={{ user: user, triggerRefresh: refreshUser }} {...props} />
}

function useUser() {
    const context = useContext(UserContext)
    return context;
}

export { UserProvider, useUser }