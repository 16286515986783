import styles from '../../pages/Client Company/ClientCompanyModalDeleteUser.module.css'
import H2 from '../../TextStyles/H2';
import P from '../../TextStyles/P';
import ButtonDestructive from '../App/Button/ButtonDestructive';
import ButtonNoStyle from '../App/Button/ButtonNoStyle';
import React, {useState} from 'react';
import Modal from '../../modal/Modal';
import { httpDelete } from '../../modules/backend';

export default function DeletePayrollElementModal({navigate, onSuccess, onClose, elementId, payrollScheduleId, ...others}) {
	const [error, setError] = useState(false);

	async function deleteElement(){
		await httpDelete(`employee-payrolls/remove-element?id=${elementId}`)
			.then(() => {
				setError('');
				onSuccess();
			})
			.catch(function () {
				setError('Could not remove pay element or deduction');
			});
	}
	
	return <Modal onClose={onClose} {...others}>
		<div className={`${styles.ModalTitle}`}>
			<H2 className={`${styles.DeleteSchedule_H2}`}>Delete pay element / deduction</H2>
		</div>
		<div>
			<P className={`${styles.AreYouSureYouWan_H2}`}>Are you sure you want to <b>delete</b> this pay element?</P>
		</div>
		<div className={`${styles.WrapperWithPadding}`}>
			<ButtonDestructive className={`${styles.ButtonDestructive}`} text="Delete" onClick={deleteElement} name="delete"/>
		</div>
		{error &&
				<span className={`${styles.Error}`}>{error}</span>
			}
		<div className={`${styles.Wrapper}`}>
			<ButtonNoStyle className={`${styles.ButtonDestructive}`} text="Cancel" onClick={() => onClose()} type="button"/>
		</div>
	</Modal>
}
