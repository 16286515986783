import Axios from "axios";
import {accessTokenKey} from './auth/auth-constants';

export async function httpGet(url) {
    const result = await Axios.get(`${process.env.REACT_APP_API_URL}/api/${url}`, {
        headers: {
            'Authorization': `bearer ${localStorage.getItem(accessTokenKey)}`,
            'Content-Type': 'application/json',
            Pragma: 'no-cache'
        }
    });
    return result.data;
}

export async function httpPut(url, values) {
    await Axios.put(`${process.env.REACT_APP_API_URL}/api/${url}`, values, {
        headers: {
            'Authorization': `bearer ${localStorage.getItem(accessTokenKey)}`,
            Pragma: 'no-cache'
        }
    });
}

export async function httpPost(url, values) {
    return await Axios.post(`${process.env.REACT_APP_API_URL}/api/${url}`, values, {
        headers: {
            'Authorization': `bearer ${localStorage.getItem(accessTokenKey)}`,
            Pragma: 'no-cache'
        }
    });
}

export async function httpDelete(url, values) {
    return await Axios.delete(`${process.env.REACT_APP_API_URL}/api/${url}`, {
        headers: {
            'Authorization': `bearer ${localStorage.getItem(accessTokenKey)}`,
            Pragma: 'no-cache',
            'Content-Type': 'application/json'
        },
        data: values
    })
}