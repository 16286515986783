import styles from './PayrollSubmissionItem.module.css'
import { Router } from '@reach/router';
import React from 'react';
import {navigate} from '@reach/router';

export default function PayrollSubmissionItem({ className, payroll, onOpen, onSubmit,onCopy }) {

	function openSummary(id){
		navigate(`/payrollSummary/${id}`);
	}

	return <div className={`${styles.Row}`} >
		<div className={`${styles.Row_FlexWrapper} ${className ? className : ''}`}>
			<button onClick={()=>openSummary(payroll.id)} className={`${styles.OpenPayroll_Btn}`} name={`${payroll.status}-btn`}>
				<div className={`${styles.Row_FlexWrapper}`}>
					<div className={`${styles.PayPeriodWrapperWrapper_FlexWrapper} ${styles.PayPeriodWrapperWrapper_FlexWrapper}`}  >
						<div className={`${styles.TextColumnWrapperWrapper}`}>
							<span className={`${styles.TextColumnWrapper_Text}  ${styles.TextColumnWrapper_TextUnderlined} `}>
								{payroll.payPeriod}
							</span>
						</div>
					</div>
					<div className={`${styles.PaymentDateWrapperWrapper_FlexWrapper}`}>
						<div className={`${styles.TextColumnWrapperWrapper}`}>
							<span className={`${styles.TextColumnWrapper_Text}`}>
								{payroll.paymentDate}
							</span>
						</div>
					</div>
					<div className={`${styles.StatusWrapperWrapper_FlexWrapper}`}>
						<div className={`${styles.TextColumnWrapperWrapper}`}>
							<span className={`${styles.TextColumnWrapper_Text}`}>
								{payroll.status}
							</span>
						</div>
					</div>
				</div>
			</button>
		</div>
	</div>		
}