import styles from './SeachInput.module.css'
import IconSearch from '../../../Images/IconSearch.svg';
import React from 'react';
import { debounce } from "lodash";

export default function SearchInput({className, label, name, search, clearSearch, minimumSearchLength = 3, ...other}) {

	function handleChange(){

		var value = document.getElementById('search-input').value
		if (value.length >= minimumSearchLength) {
			search(value)
		}

		if(value.length <= minimumSearchLength){
			clearSearch();
		}
	}

	var DoSearchWhenUserStoppedTyping = debounce((e) => {
		handleChange()
	  }, 300);


	return <div className={`${className ? className : ''} ${styles.Search}`}>
		<label className={`${styles.InputTextWithIcon_Label}`} htmlFor={name}>
			{label}
		</label>
		<div className={`${styles.InputTextWithIcon_Wrapper}`}>
			<input id="search-input" className={`${styles.InputTextWithIcon_Input}`} type="text" name={name} {...other} onChange={DoSearchWhenUserStoppedTyping}/>
			<img className={`${styles.InputTextWithIcon_Icon}`} src={IconSearch} alt="Icon"/>
		</div>
	</div>
}
