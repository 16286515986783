import React from 'react';
import IconValid from '../Icon/IconValid';
import IconNotValid from '../Icon/IconNotValid';

export default function ValidationIcons({valid}) {
	return <>
            {valid===true &&
                <IconValid/>
            }
            {valid===false &&
                <IconNotValid className={'Validation_Failed'}/>
            }
    </>
}
