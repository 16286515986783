import React, { useEffect, useState } from 'react'
import styles from './MassAddEmployeesToPayrollSchedule.module.css'
import FormInputCheckbox from '../../Symbols/App/Form/FormInputCheckbox'
import FormInputCheckboxRight from '../../Symbols/App/Form/FormInputCheckboxRightAligned'
import { httpGet, httpPost } from '../../modules/backend'
import ButtonPrimary from '../../Symbols/App/Button/ButtonPrimary'
import { navigate } from '@reach/router'
import IconInfo from '../../Symbols/App/Icon/IconInfo';

export default function MassAddEmployeesToPayrollSchedule(paymentSchedule) {

    const [paymentScheduleName, setPaymentScheduleName] = useState('');
    const [employees, setEmployees] = useState([]);
    const [employeeIdsToMove, setEmployeeIdsToMove] = useState([]);
    const [error, setError] = useState('');
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    useEffect(() => {
        async function getEmployeesNotInSchedule() {
            const result = await httpGet(`payment-schedule/employees-not-in-schedule?paymentScheduleId=${paymentSchedule.paymentScheduleId}`)
            setPaymentScheduleName(result.paymentScheduleName);
            setEmployees(result.items.map(x => ({ selected: false, id: x.id, name: x.name, isInExistingSubmission: x.isInExistingSubmission })))
        }
        getEmployeesNotInSchedule();
    }, [paymentSchedule])

    async function handleCheckBoxClicked(employeeId, index) {
        if (employeeIdsToMove.includes(employeeId)) {
            const newEmployeesArr = [...employees];
            newEmployeesArr[index].selected = false;
            setEmployees(newEmployeesArr);
            const newEmployeesToMoveArr = employeeIdsToMove.filter(x => x !== employeeId)
            setEmployeeIdsToMove(newEmployeesToMoveArr);
        } else {
            const newEmployeesArr = [...employees];
            newEmployeesArr[index].selected = true;
            setEmployees(newEmployeesArr);
            setEmployeeIdsToMove(setEmployeesToMove => [...setEmployeesToMove, employeeId])
        }
    }

    async function handleCheckAllClicked() {
        if (selectAllChecked) {
            const newEmployeesArr = employees.map(x => ({ selected: false, id: x.id, name: x.name,isInExistingSubmission: x.isInExistingSubmission }));
            setEmployees(newEmployeesArr)
            setEmployeeIdsToMove([]);
            setSelectAllChecked(false);
        } else {
            const newEmployeesArr = employees.map(x => ({
                id: x.id,
                name: x.name,
                selected: x.isInExistingSubmission ? false : true,
                isInExistingSubmission: x.isInExistingSubmission
            }));
            setEmployees(newEmployeesArr);

            setEmployeeIdsToMove(employees.filter(x => !x.isInExistingSubmission).map(x => x.id));
            setSelectAllChecked(true);
        }
    }

    async function MoveEmployees() {

        const payload = {
            paymentScheduleId: paymentSchedule.paymentScheduleId,
            employeeIds: employeeIdsToMove
        }


        await httpPost('payment-schedule/move-employees', payload)
            .then(response => {
                navigate('../')
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.data.length === 1) {
                        setError(error.response.data[0]);
                    } else {
                        setError('There was a problem moving the employees.')
                    }
                } else {
                    setError('There was a problem moving the employees.')
                }
            });
    }

    return <div className={`${styles.Content}`}>

        <div className={`row`}>
            <div className={`columns small-10 small-offset-1`}>
                <div className={`${styles.TitleWrapperWrapper}`}>
                    <span className={`${styles.TitleWrapperWrapper_Title}`}>Add employees to payment schedule "{paymentScheduleName}"</span>
                </div>
            </div>
        </div>
        <div className={`row`}>
            <div className={`columns small-10 small-offset-1`}>
                <p className={`${styles.Breadcrum}`}><a onClick={() => navigate('../')} className={`${styles.BreadcrumLink}`}>Payment schedules</a> / employees</p>
            </div>
        </div>

        <div className={`row`}>
            <div className={`columns small-10 small-offset-1`}>
                {employees && employees.length === 0 &&
                    <p className={`${styles.NoEmployeesWarning}`}>There are no available employees to add to this payment schedule.</p>
                }

                {employees && employees.length > 0 &&
                    <div className={`${styles.ListHeader}`}>
                        <p>Name</p> <FormInputCheckboxRight label="Check all" onClick={() => handleCheckAllClicked()} />
                    </div>
                }
                {employees && employees.map((employee, i) =>
                    <div key={employee.id} className={employee.isInExistingSubmission ? `${styles.EmployeeWrapper} ${styles.EmployeeDisabled}` : `${styles.EmployeeWrapper}`}>
                        <p key={employee.id} className={`${styles.EmployeeName}`}>{employee.name}</p>
                        {employee.isInExistingSubmission &&
                            <div className={`${styles.IsInExistingWrapper}`}>
                                <IconInfo className={`${styles.Icon}`}/>
                                <p className={`${styles.IsInExisting}`}>Already in a pending or submitted payroll submission</p>
                            </div>
                        }

                        <div>
                            {employee.isInExistingSubmission ?
                                <FormInputCheckbox label="" disabled /> :
                                <FormInputCheckbox label="" onChange={() => handleCheckBoxClicked(employee.id, i)} checked={employees[i].selected} />
                            }
                        </div>
                    </div>
                )}
                {employees && employees.length > 0 &&
                    <div className={'text-right'}>
                        {employeeIdsToMove.length > 0 ?
                            <span className={`${styles.NumberEmployees}`}>{`You are adding ${employeeIdsToMove.length} employees to this schedule`}</span> :
                            <span></span>
                        }

                        <ButtonPrimary name="add-company" className={`${styles.Button}`} text="Save" onClick={() => MoveEmployees()} />
                    </div>
                }

                {error &&
                    <div className='text-right'>
                        <span className={`${styles.Error}`}>{error}</span>
                    </div>
                }
            </div>
        </div>
    </div>
}