import styles from './ClientCompanyInfo.module.css'
import ButtonGhost from '../../Symbols/App/Button/ButtonGhost';
import FormInputTextReadonlyWithLabel from '../../Symbols/App/Form/FormInputTextReadonlyWithLabel';
import React, { useState, useEffect } from 'react';
import { httpGet, httpPut } from '../../modules/backend';
import ClientUserListItem from '../../Symbols/Client/ClientUserListItem';
import { Router, navigate } from '@reach/router';
import ClientCompanyInfoModalAddUser from './ClientCompanyInfoModalAddUser';
import ClientCompanyModalDeleteUser from './ClientCompanyModalDeleteUser';
import PayrollSubmissions from '../../Symbols/PayrollSubmission/PayrollSubmissions';
import IconDelete from "../../Symbols/App/Icon/IconDelete";
import ClientCompanyModalDeleteSchedule from "../../Symbols/PayrollSubmission/DeletePayrollElementModal";

export default function ClientCompanyInfo({ companyId, navigate }) {
	const [company, setCompany] = useState();
	const [submit, setSubmit] = useState(false);
	const [users, setUsers] = useState([]);


	async function getCompany() {
		var result = await httpGet(`client-companies/${companyId}`);
		setCompany(result.company);
	}

	async function getUsers() {
		var result = await httpGet(`client-users/by-company-id/${companyId}`);
		setUsers(result.users);
	}


	useEffect(() => {
		getCompany();
		getUsers();
	}, []);


	function refreshPage() {
		getUsers();
		getCompany();
		navigate(`/company/${companyId}/`)
	}

	async function massAddEmployeesToSchedule(paymentScheduleId) {
		navigate(`/company/${companyId}/mass-add-employees-to-schedule/${paymentScheduleId}`)
	}

	async function removeNewSchedule() {

	}

	return <div className={`${styles.Content}`}>

		<Router>
			<ClientCompanyInfoModalAddUser path='/add-user' onClose={() => navigate(`/company/${companyId}/`)} onSuccess={refreshPage} companyId={companyId} />
		</Router>

		<Router>
			<ClientCompanyModalDeleteUser path='/delete-user/:userId' onClose={() => navigate(`/company/${companyId}/`)} onSuccess={refreshPage} companyId={companyId} />
		</Router>

		<div>
			{company &&
				<div className={`row`}>
					<div className={`columns medium-8 medium-offset-2 small-10 small-offset-1`}>
						<div className={`${styles.SettingTitleWrapper}`}>
							<div className={`${styles.TitleWrapperWrapper}`}>
								<span className={`${styles.TitleWrapperWrapper_Title}`}>{company.name}</span>
							</div>
							<div className={`${styles.ButtonGhostWrapperWrapper}`}>
								<ButtonGhost name="edit-company" className={`${styles.ButtonGhost}`} text="Edit company" onClick={() => navigate(`/edit-company/${companyId}`)} />
							</div>
						</div>
						<div className={`${styles.Row} row collapse`}>
							<div className={`columns small-12`}>
								<FormInputTextReadonlyWithLabel className={`${styles.ReadOnlyInput}`} value={company.email} label={"Email"}/>
								<FormInputTextReadonlyWithLabel className={`${styles.ReadOnlyInput}`} value={`${company.payeRef}`} label={"PAYE"}/>
								<FormInputTextReadonlyWithLabel className={`${styles.ReadOnlyInput}`} value={`${company.databasePath}`}  label={"Database path"}/>
							</div>
						</div>

						{company.scheduleDates && company.scheduleDates.length > 0 &&
							<div className={`${styles.SettingTitleWrapper}`}>
								<div className={`${styles.TitleWrapperWrapper}`}>
									<span className={`${styles.TitleWrapperWrapper_Title}`}>Payment schedules</span>
								</div>
							</div>
						}

						<div className={`row collapse`}>
							<div className={`columns small-12`}>
								<div className={`${styles.UsersWrapper}`} id="users">
									{company.scheduleDates &&
										company.scheduleDates.map((date, i) =>
											<div key={i} className={`${styles.ScheduleDate}`} >
												<div className={`${styles.ScheduleDateTextWrapper}`} onClick={() => massAddEmployeesToSchedule(date.id)}>
													<p className={`${styles.ScheduleDateText}`}>{date.displayText}</p>
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div>



						<div className={`${styles.SettingTitleWrapper}`}>
							<div className={`${styles.TitleWrapperWrapper}`}>
								<span className={`${styles.TitleWrapperWrapper_Title}`}>Users</span>
							</div>
							<div className={`${styles.ButtonGhostWrapperWrapper}`}>
								<ButtonGhost name="add-user" className={`${styles.ButtonGhost}`} text="Add user" onClick={() => navigate(`/company/${companyId}/add-user`)} />
							</div>
						</div>
						<div className={`row collapse`}>
							<div className={`columns small-12`}>
								<div className={`${styles.UsersWrapper}`} id="users">
									{users &&
										users.map((user, i) =>
											<ClientUserListItem key={i} user={user} navigate={navigate} companyId={companyId} />
										)
									}
								</div>
							</div>
						</div>


						<div className={`${styles.SettingTitleWrapper}`}>
								<div className={`${styles.TitleWrapperWrapper}`}>
									<span className={`${styles.TitleWrapperWrapper_Title}`}>Payroll submissions</span>
								</div>
							</div>
						<PayrollSubmissions clientCompanyId={companyId}/>

					</div>
				</div>
			}

		</div>
	</div>
}
