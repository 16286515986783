import styles from './FormInputCheckboxRightAligned.module.css'
import React from 'react';

export default function FormInputCheckboxRightAligned({className, id, name, label, checked, ...other}) {
	return <div className={`${styles.Checkbox_Wrapper} ${className ? className : ''}`}>
		<label className={`${styles.Checkbox_Label}`} htmlFor={id}>
			{label}
		</label>
		<input className={`${styles.Checkbox_Input}`} type="checkbox" name={name} id={id} checked={checked} {...other}/>
		<span className={`${styles.Checkbox_Input_Custom}`}/>
	</div>
}
