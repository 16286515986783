import styles from './PayrollSummaryList.module.css'
import React, { useEffect, useState } from 'react';
import PayrollSummaryListItem from './PayrollSummaryListItem';
import { httpGet, httpPost, httpPut } from '../../modules/backend';
import ButtonGhost from '../App/Button/ButtonGhost';
import ButtonPrimary from '../App/Button/ButtonPrimary';
import LoadingModal from '../App/Loading/LoadingModal';
import {navigate} from '@reach/router';
import LoadingIndicator from '../App/Loading/LoadingIndicator';
import FormInputCheckbox from '../App/Form/FormInputCheckbox';
import SearchInput from '../App/Search/SeachInput';

export default function PayrollSummaryList({payrollId, setTitle}) {
	const [render, setRender] = useState(false);
	const [summary, setSummary] = useState('');
	const [error, setError] = useState(false);
	const [showLoadingModal, setShowLoadingModal] = useState(false);
	const [canEdit, setCanEdit] = useState(false);
	const [searchString, setSearchString] = useState("");
	const [canResubmit, setCanResubmit] = useState(true);
	const [hasDeletedElements, setHasDeletedElements] = useState(false);
	const [clientCompanyId, setClientCompanyId] = useState();

	async function getPayrollSummary(){
		setRender(false)
		await httpGet(`payroll-submissions/summary?PayrollSubmissionId=${payrollId}`)
		.then(async function (result) {
			setSummary(result.summaryItems)
			setCanEdit(result.canSubmit)
			setTitle(result.title)
			setClientCompanyId(result.clientCompanyId)
			setCanResubmit(result.canResubmit)
			setHasDeletedElements(result.hasDeletedElements)
			if(result.hasDeletedElements){
				setError('This submission contains elements which have since been deleted')
			} else {
				setError('');
			}
			setRender(true)
		}).catch(function (error) {
			setError(error.response.data);
		});
	}

	function reloadParentFunction(){
		getPayrollSummary()
	}

	function search(searchString){
		setSearchString(searchString);
	}

	function clearSearch(){
		setSearchString("");
	}

	async function resubmitPayroll(){
		setShowLoadingModal(true)
		await httpPost('payroll-submissions/resubmit-payroll', { payrollSubmissionId: payrollId })
			.then(async function () {
				setShowLoadingModal(false)
				navigate(`/company/${clientCompanyId}`);
			})
			.catch(async function (error) {
				setShowLoadingModal(false)
				setError(error.response.data);
			})
	}


	useEffect(() => {
		getPayrollSummary()
	}, []);

	function searchPredicate(searchString) {
		const searchTerm = searchString.toLowerCase();
		return item => item.name.toLowerCase().includes(searchTerm) ||
					   item.worksNumber.toString().includes(searchTerm) || 
					   item.department.toLowerCase().includes(searchTerm);
	}

	return <>

		{render === false &&
			<div className={`${styles.LoadingWrapper}`}>
				<LoadingIndicator />
			</div>
			
			}
		{render && 
			<>
				{showLoadingModal &&
					<LoadingModal />
				}
				{error &&
					<p className={`${styles.Error}`}>{error}</p>
				}
				<div className={`${styles.ButtonWrapperTop}`}>
					<ButtonGhost text="Print" onClick={() => window.print()} />
					{canResubmit &&
						<ButtonPrimary disabled={hasDeletedElements} text="Resubmit payroll" onClick={() => resubmitPayroll()}/>
					}
				</div>

				<div>
					<SearchInput label="Search" name="search" search={search} clearSearch={clearSearch} minimumSearchLength={1} />
				</div>


				<div className={`row collapse ${styles.ListHeaderWrapper}`}>

					<div className={`columns small-3`}>
						<p className={`${styles.HeaderText}`}>Name</p>
					</div>
					<div className={`columns small-2`}>
						<p>Addition</p>
					</div>
					<div className={`columns small-5`}>
						<div className={`row`}>
							<p className={`columns small-5 ${styles.HeaderText}`}>Payment / deductions</p>
							<p className={`columns small-2 ${styles.HeaderText}`}>Qty</p>
							<p className={`columns small-4 ${styles.PaymentDeductionAmount} ${styles.HeaderText}`}>Amount</p>
							<p className={`columns small-1 end`} ></p>
						</div>
						
					</div>
					<div className={`columns small-2`}></div>
				</div>
				<div id="summary-wrapper">
					{summary &&
							summary.filter(searchPredicate(searchString)).map((summaryItem, i) =>
								<PayrollSummaryListItem key={i} data={summaryItem} hasBeenDeleted={i.deleted} payrollScheduleId={payrollId} showEdit={canEdit} setMasterShowEdit={setCanEdit} reloadParentFunction={reloadParentFunction}/>
							)
					}
				</div>
			</>
		}
	</>
}
