import styles from './AdminSettings.module.css';
import ButtonGhost from '../../Symbols/App/Button/ButtonGhost';
import {Router} from '@reach/router';
import React, {useEffect, useState} from 'react';
import { httpGet } from '../../modules/backend';
import AccountantListItem from '../../Symbols/Accountant/AccountantListItem';
import { useUser } from '../../context/UserProvider';
import AdminSettingsModalAddUser from './AdminSettingsModalAddUser';
import AdminSettingsModalDeleteUser from './AdminSettingsModalDeleteUser';
import AdminSettingsModalAddClientAdmin from './AdminSettingsModalAddClientAdmin';
import AdminSettingsModalDeleteClientAdmin from './AdminSettingsModalDeleteClientAdmin';


export default function AdminSettings({navigate}) {
	const [accountants, setAccountants] = useState([]);
	const [clientAdmins, setClientAdmins] = useState([]);
	const { user } = useUser();

	async function getAccountants() {
			var result = await httpGet(`accountants/by-accountancy-id/${user.accountancyId}`);
			setAccountants(result.accountants);
	}

	async function getClientAdmins() {
		var result = await httpGet(`client-admin/by-accountancy-id/${user.accountancyId}`);
		setClientAdmins(result);
}

	useEffect(() => {
		getAccountants();
		getClientAdmins();
	}, []);
	
	function refreshPage(){
		getAccountants();
		getClientAdmins();
		navigate('/settings');
	}

	return <div className={`${styles.Content}`}>
			<Router>
				<AdminSettingsModalAddUser path='/add-accountant'onClose={() => navigate('/settings')} onSuccess={refreshPage} accountancyId={user.accountancyId}/>
			</Router>
			<Router>
				<AdminSettingsModalDeleteUser path='/delete-accountant/:accountantId'onClose={() => navigate('/settings')} onSuccess={refreshPage} accountantId={user.accountancyId}/>
			</Router>

			<Router>
				<AdminSettingsModalAddClientAdmin path='/add-client-admin'onClose={() => navigate('/settings')} onSuccess={refreshPage} accountancyId={user.accountancyId}/>
			</Router>
			<Router>
				<AdminSettingsModalDeleteClientAdmin  path='/delete-client-admin/:accountantId'onClose={() => navigate('/settings')} onSuccess={refreshPage} accountantId={user.accountancyId}/>
			</Router>


			<div>
				<div className={`row`}>
					<div className={`columns medium-8 medium-offset-2 small-10 small-offset-1`}>

						<div  className={`${styles.SettingTitleWrapper} row`}>
							<div className={`${styles.TitleWrapperWrapper}`}>
									<span className={`${styles.TitleWrapperWrapper_Title}`}>Admin settings</span>
								</div>
							<div className={`${styles.ButtonGhostWrapperWrapper}`}>
								<ButtonGhost name="add-user" className={`${styles.ButtonGhost}`} text="Add accountant" onClick={() => navigate('/settings/add-accountant')}/>
							</div>
						</div>
						
						<div id="accountants">
							{accountants && 
								accountants.map((accountant, i) =>
										<AccountantListItem key={i} accountant={accountant} navigate={navigate}/> 
								)
							}
						</div>

						<div className={`${styles.ClinetAdminWrapper}`}>
							<div  className={`${styles.SettingTitleWrapper} row`}>
								<div className={`${styles.TitleWrapperWrapper}`}>
										<span className={`${styles.TitleWrapperWrapper_Title}`}>Client admin settings</span>
									</div>
								<div className={`${styles.ButtonGhostWrapperWrapper}`}>
								<ButtonGhost name="add-client-admin" className={`${styles.ButtonGhost}`} text="Add client admin" onClick={() => navigate('/settings/add-client-admin')}/>
								</div>
							</div>
								
							<div id="clientAdmins" >
								{clientAdmins &&
									clientAdmins.map((clientAdmin, i) =>
											<AccountantListItem key={i} accountant={clientAdmin} navigate={navigate} clientAdmin={true}/>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
}
