import styles from './FormSelect.module.css'
import IconChevronDown from '../../../Images/IconChevronDown.svg';
import React, {useState} from 'react';
import ValidationIcons from "./ValidationIcons";

export default function FormSelect({className, label, name, options, required, validateRule, ...other}) {
	const [valid, setValid] = useState();

	function validate(e){
		if(validateRule){
			setValid(validateRule(e.target.value))
		}
	}

	return <div className={`${className ? className : ''}`}>
		<label className={`${styles.Select_Label}`} htmlFor={name}>
			{label} {required ? '*' : ''}
		</label>
		<div className={`${styles.Select_Wrapper}`}>
			<select className={`${styles.Select_Select}`} required={required} name={name} onBlur={validate} {...other}>
				{options ? options.map((option) => <option value={option.value} key={option.text}>{option.text}</option>) : ''}
			</select>
			<img className={`${styles.Select_Icon}`} src={IconChevronDown} alt="Down arrow"/>
			{validateRule &&
				<div className={`${styles.InputSelect_Validation_Wrapper}`}>
					<div className={`${styles.InputSelect_Validation_Inner_Wrapper}`}>
						<ValidationIcons valid={valid}/>
					</div>
				</div>
			}
		</div>
	</div>
}
