import styles from './DatabasePathSearch.module.css'
import React, { useEffect, useState } from 'react';
import SearchInput from '../Search/SeachInput';
import ButtonGhost from '../Button/ButtonGhost';

export default function DatabasePathSearch({ databasePaths, setDataBasePath}) {
        const [searchString, setSearchString] = useState("");

        function search(searchString) {
                setSearchString(searchString);
        }

        function clearSearch() {
                setSearchString("");
        }

        function handelChangeDatabasePath(path){
                clearSearch("");
                setDataBasePath(path);
                document.getElementById('search-input').value = "";
        }

        return <div className={`${styles.DatabasePathSearchWrapper}`}>
                <SearchInput label="Search database paths" name="search" search={search} clearSearch={clearSearch} />
                {searchString != "" && databasePaths && databasePaths.length > 0 &&
                        databasePaths.filter(item => item.path.toLowerCase().includes(searchString.toLowerCase())).map((databasePath, i) =>
                                <div className={`${styles.DatabasePathSearchItem}`} key={i}>
                                <p className={`${styles.DatabasePathSearchText}`}>{databasePath.path}</p>
                                <ButtonGhost size="thin" text="Select" onClick={()=> handelChangeDatabasePath(databasePath.fullPath)}/>
                                </div>
                        )
                }
        </div>
}