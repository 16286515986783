import React, { useEffect } from 'react';
import styles from './InfiniteScroll.module.css'
import LoadingIndicator from '../Loading/LoadingIndicator';
export default function InfiniteScroll({ loadFunction, error, isLoading, hasMore }) {

  window.onscroll = function () {
    var pageHeight = document.documentElement.offsetHeight,
    windowHeight = window.innerHeight,
    scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);

    if (pageHeight - 10 <= windowHeight + scrollPosition) {
      if (error || isLoading || !hasMore) return;
      loadFunction();
    }
  }

  useEffect(() => {
    return () => {
      window.onscroll = null;
    };
  }, []);


  return <div className={`${styles.InfiniteScroll}`}>
    {isLoading === true &&
      <LoadingIndicator />
    }
  </div>
}