import styles from './RadioButtonWithLabel.module.css'
import React from 'react';

export default function RadioButtonWithLabel({ className, label, value, name,required, ...other }) {
	return <div className={`${className ? className : ""}`}>
		<div className={`${styles.CheckboxWithLabel_Container}`}>
			<label className={`${styles.CheckboxWithLabel_Label}`} htmlFor={value}>
				<input className={`${styles.CheckboxWithLabel_Checkbox}`} name={name} type="radio" value={value} id={value} {...other} />
				<span className={`${styles.CheckboxWithLabel_Checkmark}`}></span>
				<span className={`${styles.CheckboxWithLabel_Label_Text}`}>{required ? `${label} *` : label}</span>
			</label>
		</div>
	</div>
}
