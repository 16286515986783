import styles from './ClientCompanyScheduledDate.module.css'
import H2 from '../../TextStyles/H2';
import FormInputText from '../../Symbols/App/Form/FormInputText';
import FormInputCheckbox from '../../Symbols/App/Form/FormInputCheckbox';
import ButtonGhost from '../../Symbols/App/Button/ButtonGhost';
import React, { useState, useEffect } from 'react';
import Modal from '../../modal/Modal';
import { httpGet, httpPut } from '../../modules/backend';
import useForm from '../../hooks/useForm';
import FormSelect from '../../Symbols/App/Form/FormSelect';
import RadioButtonWithLabel from '../../Symbols/App/Form/RadioButtonWithLabel';
import SelectDayOfMonth from '../../Symbols/App/Select Day/SelectDayOfMonth';
import SelectDayOfWeek from '../../Symbols/App/Select Day/SelectDayOfWeek'

export default function ClientCompanyScheduledDate({ navigate, type, company, onClose, dateAdded, ...others }) {
	const [error, setError] = useState(false);
	const [radioOption, setRadioOption] = useState();
	const [paymentFrequency, setPaymentFrequency] = useState();
	const [onTheFrequency, setOnTheFrequency] = useState();
	const [onTheDay, setOnTheDay] = useState();
	const [dayOfMonth, setDayOfMonth] = useState();
	const [dayOfWeek, setDayOfWeek] = useState(1);
	const [paymentFrequencyWeekly, setPaymentFrequencyWeekly] = useState(1);
	const { values, handleChange, handleSubmit } = useForm(onFormSubmit);

	async function onFormSubmit() {
		var okToSubmit = true;

		var scheduledDate = {
			"paymentFrequency": -1,
			"displayText": "",
			"onThePaymentFrequency": "",
			"onThePaymentDay":""
		}

		if(paymentFrequency === "Monthly"){
			scheduledDate.paymentFrequency = -1
			if(radioOption === 'each'){
				if(dayOfMonth == 0 || dayOfMonth == undefined){
					setError('Please select a day of the month');
					okToSubmit = false;
				}
				scheduledDate.paymentDay = dayOfMonth;
				scheduledDate.onThePaymentDay = "";
				scheduledDate.onThePaymentFrequency = "";
			}else if(radioOption === 'onThe'){
				scheduledDate.paymentDay = 0;
				//save onTheFrequency & onTheDay
				scheduledDate.onThePaymentDay = onTheDay;
				scheduledDate.onThePaymentFrequency = onTheFrequency;
			}else{
				setError('Please choose an option from above');
				okToSubmit = false;
			}
		}else{//if weekly
			scheduledDate.onThePaymentDay = "";
			scheduledDate.onThePaymentFrequency = "";
			if(paymentFrequency === "Weekly"){
				scheduledDate.paymentFrequency = 7;
			}else if(paymentFrequency === "Fortnightly"){
				scheduledDate.paymentFrequency = 14;
			}else if(paymentFrequency === "Four Weeks"){
				scheduledDate.paymentFrequency = 28;
			}
			scheduledDate.paymentDay = dayOfWeek;
		}

		scheduledDate.displayText = getDisplayText(scheduledDate);
		scheduledDate.canDelete = true;
		
		if(okToSubmit){
			company.scheduleDates.push(scheduledDate)
			onClose();
		}
	}

	function getDisplayText(scheduledDate){
		if (scheduledDate.onThePaymentFrequency!="")
                {
                    return paymentFrequency + " on the " + scheduledDate.onThePaymentFrequency.toLowerCase() + " " + scheduledDate.onThePaymentDay.toLowerCase();
                }
                if (paymentFrequency == "Weekly" || paymentFrequency == "Fortnightly" || scheduledDate.paymentFrequency == "Four Weeks" || scheduledDate.paymentFrequency == 28)
                {
                    return paymentFrequency + " on a " + paymentDayToDayOfWeek(scheduledDate.paymentDay);
                }
                return paymentFrequency + " on the "+ paymentDayToDayOfMoth(scheduledDate.paymentDay);
	}

	function paymentDayToDayOfWeek(day)
        {
            var dayString = "";
            switch (day)
            {
                case 1:
                    dayString = "monday";
                    break;
                case 2:
                    dayString = "tuesday";
                    break;
                case 3:
                    dayString = "wednesday";
                    break;
                case 4:
                    dayString = "thursday";
                    break;
                case 5:
                    dayString = "friday";
                    break;
                case 6:
                    dayString = "saturday";
                    break;
                case 7:
                    dayString = "sunday";
                    break;
                default:
                    dayString = "";
                    break;
            }

            return dayString;
        }

        function paymentDayToDayOfMoth(day)
        {
            var dayString = "";
            if (day % 10 == 1 && day != 11)
            {
                dayString = "st";
            }else if (day % 10 == 2 && day != 12)
            {
                dayString = "nd";
            }
            else if (day % 10 == 3 && day != 13)
            {
                dayString = "rd";
            }
            else
            {
                dayString = "th";
            }

            return day+dayString;
        }

	var paymentFrequencyOptions = [
		{ text: "Monthly", value: "Monthly" },
		{ text: "Weekly", value: "Weekly" },
		{ text: "Fortnightly", value: "Fortnightly" },
		{ text: "Four Weeks", value: "Four Weeks" }
	];

	async function handleChangeFrequency(event) {
		setPaymentFrequency(event.target.value)
		if(event.target.value === "Weekly"){
			setPaymentFrequencyWeekly(1)
		}else if(event.target.value === "Fortnightly"){
			setPaymentFrequencyWeekly(2)
		}else if(event.target.value === "Four Weeks"){
			setPaymentFrequencyWeekly(4)
		}
	}

	var onTheFrequencyOptions = [
		{ text: "First", value: "First" },
		{ text: "Last", value: "Last" },
		{ text: "Second", value: "Second" },
		{ text: "Third", value: "Third" },
		{ text: "Fourth", value: "Fourth" },
	];

	var onTheDayOptions = [
		{ text: "Day", value: "Day" },
		{ text: "Week day", value: "Week day" },
		{ text: "Monday", value: "Monday" },
		{ text: "Tuesday", value: "Tuesday" },
		{ text: "Wednesday", value: "Wednesday" },
		{ text: "Thursday", value: "Thursday" },
		{ text: "Friday", value: "Friday" },
		{ text: "Saturday", value: "Saturday" },
		{ text: "Sunday", value: "Sunday" },
	];

	function handelDayOfMonth(dayOfMonth) {
		setDayOfMonth(dayOfMonth);
		//set radio button to 'Each'
		setRadioOption('each');
	}

	function handelDayOfWeek(dayOfWeek) {
		setDayOfWeek(dayOfWeek);
	}

	function handleRadioChange(event) {
		setRadioOption(event.target.value);
		if(event.target.value === 'each' ){
			setOnTheFrequency("")
			setOnTheDay("")
			setDayOfMonth(1);
		}else{
			if(onTheFrequency === undefined){
				setOnTheFrequency("First")
				setOnTheDay("Day")
				setDayOfMonth(0);
			}
		}
	}

	function handleOnTheFrequency(event) {
		setOnTheFrequency(event.target.value);
		setRadioOption('onThe');
		setDayOfMonth(0);
	}

	function handleOnTheDay(event) {
		setOnTheDay(event.target.value);
		setRadioOption('onThe');
		setDayOfMonth(0);
	}

	function handlePaymentFrequencyWeekly(event){
		setPaymentFrequencyWeekly(event.target.value)
	}

	useEffect(() => {
		setPaymentFrequency("Monthly");
	}, []);


	return <Modal onClose={onClose} { ...others}>
		<div className={`${styles.ModalTitle}`}>
			<H2 className={`${styles.AddANewUser_H2}`}>{type} date</H2>
		</div>
		<form className={`${styles.FormWrapper}`} onSubmit={handleSubmit}>
			<FormSelect options={paymentFrequencyOptions} label="Frequency" name="paymentFrequency" onChange={handleChangeFrequency} value={paymentFrequency} />

			<div>
				{paymentFrequency === "Monthly" &&
					<>
						<div className={`${styles.FormSection}`}>
							<RadioButtonWithLabel label={"Each"} name="test" value="each" checked={radioOption === 'each'} onChange={handleRadioChange} />
							<div className={`row collapse`}>
								<div className={`columns small-12 medium-6 collapse`}>
									<p className={`${styles.FormText}`} >month on:</p>
								</div>
								<div className={`columns small-12 medium-6 collapse`}>
									<SelectDayOfMonth dayOfMonth={dayOfMonth} onSelect={handelDayOfMonth} />
								</div>
							</div>
						</div>
						<div className={`${styles.FormSection}`}>
							<RadioButtonWithLabel label={"On the"} name="test" value="onThe" checked={radioOption === 'onThe'} onChange={handleRadioChange} />
							<div className={`row collapse`}>
								<div className={`columns small-12 medium-6`}>
									<FormSelect className={`${styles.PaymentDate}`} options={onTheFrequencyOptions} name={`${type}paymentFrequency`} onChange={handleOnTheFrequency} value={onTheFrequency} />
								</div>
								<div className={`columns small-12 medium-6`}>
									<FormSelect className={`${styles.PaymentDay}`} options={onTheDayOptions} name="paymentDay" onChange={handleOnTheDay} value={onTheDay} />
								</div>
							</div>
						</div>

					</>
				}

				{(paymentFrequency === "Weekly" || paymentFrequency === "Fortnightly" || paymentFrequency === "Four Weeks") &&
					<div className={`${styles.FormSection}`}>
						<div className={`row collapse`}>
							<div className={`columns small-12 medium-6 ${styles.FormImputEverWeeksWrapper}`}>
								<p className={`${styles.FormText} ${styles.FormTextWithInput}`} >Every {paymentFrequencyWeekly} week(s) on:</p>
							</div>
							<div className={`columns small-12 medium-6`}>
								<SelectDayOfWeek dayOfWeek={dayOfWeek} onSelect={handelDayOfWeek} />
							</div>
						</div>
					</div>
				}
			</div>

			{error &&
				<span className={`${styles.Error}`}>{error}</span>
			}

			<div className={`${styles.ButtonWrapper}`}>
				<ButtonGhost className={`${styles.ButtonGhost}`} text="Add" type="submit" name="save" />
			</div>
		</form>
	</Modal>
}
