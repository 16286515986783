import styles from './AdminSettingsModalAddUser.module.css'
import H2 from '../../TextStyles/H2';
import useForm from '../../hooks/useForm';
import FormInputText from '../../Symbols/App/Form/FormInputText';
import ButtonPrimary from '../../Symbols/App/Button/ButtonPrimary';
import React, {useState}from 'react';
import Modal from '../../modal/Modal';
import { httpPost } from '../../modules/backend';

export default function AdminSettingsModalAddClientAdmin({navigate, onSuccess, accountancyId, ...others}) {
	const [error, setError] = useState(false);
	const { values, handleChange, handleSubmit } = useForm(onFormSubmit);

	async function onFormSubmit() {
		values.accountancyId = accountancyId
		await httpPost('client-admin', values)
		.then(async function (response) {
			if(response.data.success){
				onSuccess();
			}else{
				setError("Email is in use by another user.");
			}
		})
		.catch(function () {
			setError("Could not save user.");
		});
	}

	return <Modal {...others}>
		<div className={`${styles.ModalTitle}`}>
			<H2 className={`${styles.AddANewUser_H2}`}>Add a new client admin</H2>
		</div>
		<form className={`${styles.FormWrapper}`} onSubmit={handleSubmit}>
			<div className={`${styles.InputWrapper}`}>
				<FormInputText label="First name" name="firstName" onChange={handleChange} value={values.firstName || ''} required/>
			</div>
			<div className={`${styles.InputWrapper}`}>
				<FormInputText label="Last name" name="lastName" onChange={handleChange} value={values.lastName || ''} required/>
			</div>
			<div className={`${styles.InputWrapper}`}>
				<FormInputText label="Email address" name="email" onChange={handleChange} value={values.email || ''} type="email" required/>
			</div>
			{error &&
				<span className={`${styles.Error}`}>{error}</span>
			}
			<div className={`${styles.ButtonWrapper}`}>
				<ButtonPrimary className={`${styles.ButtonPrimary}`} text="Add client admin" type="submit" name="submit"/>
			</div>
		</form>
	</Modal>
}
