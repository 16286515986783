import styles from './NavigationDropDown.module.css'
import {Router} from '@reach/router';
import React from 'react';
import { navigate } from '@reach/router';

export default function NavigationDropDown({className,auth,onClose}) {

	const onLogoutClick = async function() {
        await auth.logout();
	};
	
	function goToDashboard(){
		navigate('/');
		onClose()
	}

	function goToSettings(){
		navigate('/settings');
		onClose()
	}

	return <div className={`${styles.Navigation} ${className ? className : ''}`}>
		<span className={`${styles.Navigation_Dashboard}`} onClick={goToDashboard}>
			Dashboard
		</span>
		<span className={`settings-btn ${styles.Navigation_AdminSettings}`} onClick={goToSettings}>
			Admin settings
		</span>
		<span className={`${styles.Navigation_Logout}`} onClick={onLogoutClick}>
			Log out
		</span>
	</div>
}
