import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import styles from './Secure.module.css';
import Loader from '../../Images/Loader.svg';
import { useUser } from '../../context/UserProvider';
import LoadingIndicator from '../../Symbols/App/Loading/LoadingIndicator'

export default function LoginCallback({auth}) {
    const { triggerRefresh, user } = useUser();

    useEffect(() => {
        (async () => {
            if(!user){
                await auth.completeSignin();
                await triggerRefresh();
                navigate('/');
            }
            
        })();
    });

    return (<>
        <div className={`${styles.loader_overlay}`}>
            <div className={`${styles.loader}`}>
                <div className={`${styles.loader_content}`}>
                    <LoadingIndicator/>
                </div>
            </div>
        </div>
    </>);
}