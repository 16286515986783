import styles from './EditClientCompany.module.css'
import FormInputText from '../../Symbols/App/Form/FormInputText';
import ButtonPrimary from '../../Symbols/App/Button/ButtonPrimary';
import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';
import ClientCompanyScheduledDate from './ClientCompanyScheduledDate';
import { httpGet, httpPut } from '../../modules/backend';
import {NotEmptyString, ValidatePaye} from "../../modules/Validation";
import DatabasePathSearch from '../../Symbols/App/DatabasePathSearch/DatabasePathSearch';
import FormInputTextReadonlyWithLabel from '../../Symbols/App/Form/FormInputTextReadonlyWithLabel';
import ClientCompanyModalDeleteSchedule from "../../Symbols/PayrollSubmission/DeletePayrollElementModal";
import IconDelete from "../../Symbols/App/Icon/IconDelete";


export default function EditClientCompanyModal({ companyId, navigate, onSuccess, ...others }) {
	const [error, setError] = useState(false);
	const [company, setCompany] = useState()
	const [companyName, setCompanyName] = useState("")
	const [companyPath, setCompanyPath] = useState("")
	const [companyEmail, setCompanyEmail] = useState("")
	const [companyPaye, setCompanyPaye] = useState("")
	const [sagePassword, setSagePassword] = useState("");
	const [databasePaths, setDatabasePaths] = useState([]);

	async function onFormSubmit() {
		if (validate()) {
			setError();

			const databasePathExists = await httpGet(`database?databasePath=${encodeURIComponent(companyPath)}`)
			if (!databasePathExists) {
				setError(`Database path ${companyPath} does not exist.`)
			} else {

				company.name = companyName;
				company.email = companyEmail;
				company.payeRef = companyPaye;
				company.databasePath = companyPath;
				company.passwordOverride = sagePassword;
				await httpPut(`client-companies/${company.id}`, company)
					.then(async function (response) {
						//navigate to dashboard
						navigate(`/company/${companyId}`)
					})
					.catch(function () {
						setError("Could not save changes.");
					});
			}
		}
	}

	function validate() {
		var okToSubmit = true;

		if(!ValidatePaye(companyPaye)) {
			setError("Please enter a valid PAYE.")
			okToSubmit = false;
		}
		if (companyPath == "") {
			setError("Please add a database path.");
			okToSubmit = false;
		}
		if (company.scheduleDates.length == 0) {
			setError("Please add a payment schedule.");
			okToSubmit = false;
		}

		if (companyName == "") {
			setError("Please add a name.");
			okToSubmit = false;
		}

		return okToSubmit
	}

	function refreshPage() {
	}

	function handleCompanyName(event) {
		setCompanyName(event.target.value);
	}

	function handleCompanyPath(string) {
		var replacedString = string.replace(/\//g, "\\");
		setCompanyPath(replacedString);
	}

	function handleCompanyEmail(event) {
		setCompanyEmail(event.target.value);
	}

	function handleCompanyPaye(event) {
		setCompanyPaye(event.target.value);
	}

	function handleSagePassword(event) {
		setSagePassword(event.target.value);
	}

	async function getCompany() {
		var result = await httpGet(`client-companies/${companyId}`);
		setCompany(result.company);
		setCompanyName(result.company.name);
		setCompanyEmail(result.company.email);
		setCompanyPaye(result.company.payeRef)
		setCompanyPath(result.company.databasePath);
		setSagePassword(result.company.sagePassword);
	}

	useEffect(() => {
		getCompany();
	}, []);

	useEffect(() => {
		GetDatabasePaths();

		async function GetDatabasePaths() {
			var result = await httpGet(`client-companies/possible-databases-from-db`);

			setDatabasePaths(result);
		}
	}, []);

	function handleScheduleDeletion(date) {
		var comp = {...company};
		var index = comp.scheduleDates.indexOf(date);
		if(index !== -1) {
			comp.scheduleDates.splice(index, 1);
			setCompany(comp);
		}
	}

	return <div className={`${styles.Content}`}>
		<Router>
			<ClientCompanyScheduledDate type="Payment" path='/edit-payment-date' onClose={() => navigate(`/edit-company/${companyId}`)} onSuccess={refreshPage} company={company} dateAdded={(company) => setCompany(company)} />
		</Router>

		{/*<Router>*/}
		{/*	<ClientCompanyModalDeleteSchedule path='/delete-payment-schedule/:scheduleId' onClose={() => navigate(`/edit-company/${companyId}`)} onSuccess={refreshPage} companyId={companyId} />*/}
		{/*</Router>*/}

		{company &&
			<div className={`row`}>
				<div className={`columns medium-8 medium-offset-2 small-10 small-offset-1`}>
					<div className={`${styles.SettingTitleWrapper}`}>
						<div className={`${styles.TitleWrapperWrapper}`}>
							<span className={`${styles.TitleWrapperWrapper_Title}`}>Edit company</span>
						</div>
					</div>
					<div className={`${styles.InputWrapper}`}>
						<FormInputText label="Name" name="name" onChange={handleCompanyName} value={companyName}  required validateRule={NotEmptyString}/>
					</div>
					<div className={`${styles.InputWrapper}`}>
						<FormInputText label="Email" name="email" onChange={handleCompanyEmail} value={companyEmail}  required validateRule={NotEmptyString}/>
					</div>
					<div className={`${styles.InputWrapper}`}>
						<FormInputText label="PAYE" name="paye" onChange={handleCompanyPaye} value={companyPaye}  required validateRule={NotEmptyString} validateRule={ValidatePaye} toolTipText="3 digits followed by / between 5 and 7 alphanumerics e.g. 123/A1B2C3"/>
					</div>
					<div className={`${styles.InputWrapper} ${styles.DatabasePathWrapper}`}>
						<DatabasePathSearch databasePaths={databasePaths} setDataBasePath={handleCompanyPath}/>
						{companyPath && companyPath != "" &&
							<FormInputTextReadonlyWithLabel className={`${styles.ReadOnlyInput}`} value={companyPath}  label={"Database path"}/>
						}
					</div>
					<div className={`${styles.InputWrapper}`}>
						<FormInputText label="Sage password" name="sagePassword" type="password" onChange={handleSagePassword} value={sagePassword} toolTipText={"Enter the password used to login to Sage for this company if it differs from the default password"}/>
					</div>
					<div className={`${styles.AddMemoWrapperWrapperItem}`}>
						<button  onClick={() => navigate(`/edit-company/${companyId}/edit-payment-date`)}>
								<p className={`${styles.AddScheduleDate}`}>Add payment date</p>
						</button>
					</div>

					{company.scheduleDates && company.scheduleDates.length > 0 &&
					<div className={`${styles.PaymentSchelduesWrapper}`}>
						<div className={`${styles.TitleWrapperWrapper}`}>
							<span className={`${styles.TitleWrapperWrapper_Title}`}>Payment schedules</span>
						</div>
					</div>
					}

					<div className={`row collapse`}>
						<div className={`columns small-12`}>
							<div className={`${styles.UsersWrapper}`} id="users">
								{company.scheduleDates &&
									company.scheduleDates.map((date, i) =>
										<div key={i} className={`${styles.ScheduleDate}`}>
											<div className={`${styles.ScheduleDateTextWrapper}`}>
												<p key={i} className={`${styles.ScheduleDateText}`}>{date.displayText}</p>
											</div>
											{date.canDelete &&
												<div className={`${styles.IconWrapper}`}>
													<div className={`deleteUser-${date.displayText}`}
													onClick={() => handleScheduleDeletion(date)}>
														<IconDelete className={`${styles.IconDelete}`}/>
													</div>
												</div>
											}
										</div>
									)
								}
							</div>
						</div>
					</div>

					{error &&
						<span className={`${styles.Error}`}>{error}</span>
					}
					<div className={`${styles.ButtonWrapper}`}>
						<ButtonPrimary className={`${styles.ButtonGhost}`} text="Save" type="submit" name="submit" onClick={onFormSubmit} />
					</div>
				</div>
			</div>
		}
	</div>
}
