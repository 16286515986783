import React, { useEffect } from "react";
import styles from './Secure.module.css';
import Loader from '../../Images/Loader.svg';
import { useUser } from '../../context/UserProvider';
import LoadingIndicator from '../../Symbols/App/Loading/LoadingIndicator';

export default function Secure({ auth, children }) {
    const { user } = useUser();
    
    async function login() {
        await auth.login();
    }
    useEffect(() => {
        if (!auth.isAuthenticated()) {          
            login();
        }
    });

    return (
        <>
            {auth.isAuthenticated() && user && children}
            {(!auth.isAuthenticated() || !user) &&
                <div className={`${styles.loader_overlay}`}>
                    <div className={`${styles.loader}`}>
                        <div className={`${styles.loader_content}`}>
                            <LoadingIndicator />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}