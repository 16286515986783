import styles from './HeaderDropdownMenu.module.css'
import Logo from '../../../Images/Logo.svg';
import Avatar from '../../../Images/Avatar.svg';
import React, { useState } from 'react';
import NavigationDropDown from '../Navigation/NavigationDropDown'

export default function HeaderDropdownMenu({auth}) {
	const [showMenu, setShowMenu] = useState(false);

	function closeMenu(){
		setShowMenu(false)
	}

	return <div className={`${styles.MenuWrapper} columns small-6 text-right`}>
		{showMenu &&
			<div id="menuTransparency" className={`${styles.MenuTransparency}`} onClick={closeMenu}></div>
		}
		
		<div className={`menu-btn ${styles.AvatarWrapper}`} onClick={() => setShowMenu(!showMenu)}>
			<img className={`${styles.Avatar}`} src={Avatar} alt="Avatar" />
			{showMenu &&
				<div className={`${styles.DropdownWrapper}`}>
					<NavigationDropDown onClose={closeMenu} auth={auth}/>
				</div>
			}
		</div>
	</div>
}
