import Close from '../Images/Close.svg';
import React from 'react';
import styles from './Modal.module.css'

export default function Modal({ children, width, onClose, ...others }) {

    let style = '';

    return (<div className={`modal modal--open ${styles.Modal}`} id="modal">
        <div className="modal_overlay"></div>
        <div className="modal_content">
            <div className={`${styles.Dialog} ${style}`}>
                <a onClick={onClose} title={'Close'}>
                    <img src={Close} alt={'Close'} className={`${styles.Close}`} />
                </a>
                {children}
            </div>
        </div>
    </div>)
}