import styles from './HeaderLoggedIn.module.css'
import Logo from '../../../Images/Logo.svg';
import React from 'react';
import HeaderDropdownMenu from './HeaderDropdownMenu';
import {navigate} from '@reach/router';

export default function HeaderLoggedIn({className,auth}) {

	return <div className={`${styles.Header} ${className ? className : ''}`}>
		<div className={`${styles.Row} row full-width collapse`}>
			<div className={`columns small-6 text-right`}>
				<div className={`${styles.LogoWrapperWrapper}`} onClick={()=>navigate(`/`)}>
					<img src={Logo} alt="Logo"/>
				</div>
			</div>
			<HeaderDropdownMenu auth={auth}/>
		</div>
	</div>
}
