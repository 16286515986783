import styles from './CompanyListItem.module.css'
import React from 'react';

export default function CompanyListItem({className, company, navigate}) {
	return <button className={`${styles.Tr} ${className ? className : ''} company`} name={`${company.name}-btn`} onClick={() => navigate(`/company/${company.id}`)}>
		<div className={`${styles.Row}`}>
			<div className={`columns small-12`}>
				<div className={`${styles.TextColumn2WrapperWrapper}`}>
					<span className={`${styles.TextColumn2WrapperWrapper_TextColumn2}`}>
						{company.name}
					</span>
				</div>
			</div>
		</div>
	</button>
}
