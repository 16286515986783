import styles from './SelectDayOfWeek.module.css'
import React, {useState, useEffect}from 'react';

export default function SelectDayOfWeek({onSelect, dayOfWeek}) {
	const [value, setValue] = useState();

	function onClickHandeler(value){
		setValue(value)
		onSelect(value)
	}

	useEffect(() => {
		setValue(dayOfWeek)
	}, [dayOfWeek]);

	return <div className={`${styles.SelectDayOfMonth}`}>
			<button type="button" className={`${value === 1 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(1)}>M</button>
			<button type="button" className={`${value === 2 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(2)}>T</button>
			<button type="button" className={`${value === 3 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(3)}>W</button>
			<button type="button" className={`${value === 4 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(4)}>T</button>
			<button type="button" className={`${value === 5 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(5)}>F</button>
			<button type="button" className={`${value === 6 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(6)}>S</button>
			<button type="button" className={`${value === 7 ?  styles.SelectDayOfMonthButtonSelected : styles.SelectDayOfMonthButton}`} onClick={()=>onClickHandeler(7)}>S</button>
	</div>
}
