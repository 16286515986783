import styles from './AddClientCompany.module.css'
import FormInputText from '../../Symbols/App/Form/FormInputText';
import ButtonPrimary from '../../Symbols/App/Button/ButtonPrimary';
import React, { useState, useEffect } from 'react';
import { httpPost, httpGet } from '../../modules/backend';
import { Router } from '@reach/router';
import ClientCompanyScheduledDate from './ClientCompanyScheduledDate';
import LoadingModal from "../../Symbols/App/Loading/LoadingModal";
import {NotEmptyString, ValidatePaye} from "../../modules/Validation";
import DatabasePathSearch from '../../Symbols/App/DatabasePathSearch/DatabasePathSearch';
import FormInputTextReadonlyWithLabel from '../../Symbols/App/Form/FormInputTextReadonlyWithLabel';

export default function AddClientCompanyModal({ navigate, onSuccess, ...others }) {
	const [error, setError] = useState(false);
	const [company, setCompany] = useState()
	const [companyName, setCompanyName] = useState("")
	const [companyPath, setCompanyPath] = useState("")
	const [companyEmail, setCompanyEmail] = useState("")
	const [companyPaye, setCompanyPaye] = useState("")
	const [sagePassword, setSagePassword] = useState("")
	const [showLoadingModal, setShowLoadingModal] = useState(false);
	const [databasePaths, setDatabasePaths] = useState([]);

	async function onFormSubmit() {
		if (validate()) {
			setError(false);
			setShowLoadingModal(true);
			const databasePathExists = await httpGet(`database?databasePath=${encodeURIComponent(companyPath)}`)
			if (!databasePathExists) {
				setShowLoadingModal(false);
				setError(`Database path ${companyPath} does not exist.`)
			} else {

				company.name = companyName;
				company.email = companyEmail;
				company.payeRef = companyPaye;
				company.databasePath = companyPath;
				company.passwordOverride = sagePassword;

				await httpPost(`client-companies`, company)
					.then(async function (response) {
						//navigate to dashboard
						setShowLoadingModal(false);
						navigate(`/`)
					})
					.catch(function () {
						setShowLoadingModal(false);
						setError("Could not save changes.");
					});
			}
		}
	}

	function validate() {
		var okToSubmit = true;

		if(!ValidatePaye(companyPaye)) {
			setError("Please enter a valid PAYE.")
			okToSubmit = false;
		}
		if (companyPath == "") {
			setError("Please add a database path.");
			okToSubmit = false;
		}
		if (company.scheduleDates.length == 0) {
			setError("Please add a payment schedule.");
			okToSubmit = false;
		}

		if (companyName == "") {
			setError("Please add a name.");
			okToSubmit = false;
		}

		return okToSubmit
	}

	function handleCompanyName(event) {
		setCompanyName(event.target.value);
	}

	function handleCompanyPath(string) {
		var replacedString = string.replace(/\//g, "\\");
		setCompanyPath(replacedString);
	}

	function handleCompanyEmail(event) {
		setCompanyEmail(event.target.value);
	}

	function handleCompanyPaye(event) {
		setCompanyPaye(event.target.value);
	}

	function handleSagePassword(event) {
		setSagePassword(event.target.value);
	}


	useEffect(() => {
		setCompany({
			"name": "",
			"databasePath": "",
			"email": "",
			"payeRef": "",
			"scheduleDates" : []
		})
	}, []);

	useEffect(() => {
		GetDatabasePaths();

		async function GetDatabasePaths() {
			var result = await httpGet(`client-companies/possible-databases-from-db`);
			setDatabasePaths(result);
		}
	}, []);


	return <div className={`${styles.Content}`}>
		{showLoadingModal &&
			<LoadingModal />
		}
		<Router>
			<ClientCompanyScheduledDate type="Payment" path='/edit-payment-date' onClose={() => navigate(`/add-company`)} company={company}/>
		</Router>
		{company &&
			<div className={`row`}>
				<div className={`columns medium-8 medium-offset-2 small-10 small-offset-1`}>
					<div className={`${styles.SettingTitleWrapper}`}>
						<div className={`${styles.TitleWrapperWrapper}`}>
							<span className={`${styles.TitleWrapperWrapper_Title}`}>Add company</span>
						</div>
					</div>
					<div className={`${styles.InputWrapper}`}>
						<FormInputText label="Name" name="name" onChange={handleCompanyName} value={companyName} required validateRule={NotEmptyString}/>
					</div>
					<div className={`${styles.InputWrapper}`}>
						<FormInputText label="Email" name="email" onChange={handleCompanyEmail} value={companyEmail} required validateRule={NotEmptyString}/>
					</div>
					<div className={`${styles.InputWrapper}`}>
						<FormInputText label="PAYE" name="paye" onChange={handleCompanyPaye} value={companyPaye} required validateRule={ValidatePaye} toolTipText="3 digits followed by / between 5 and 7 alphanumerics e.g. 123/A1B2C3"/>
					</div>
					<div className={`${styles.InputWrapper} ${styles.DatabasePathWrapper}`}>
						<DatabasePathSearch databasePaths={databasePaths} setDataBasePath={handleCompanyPath}/>
						{companyPath && companyPath != "" &&
							<FormInputTextReadonlyWithLabel className={`${styles.ReadOnlyInput}`} value={companyPath}  label={"Database path"}/>
						}
					</div>



					<div className={`${styles.InputWrapper}`}>
						  <FormInputText label="Sage password" name="sagePassword" type="password" onChange={handleSagePassword} value={sagePassword} toolTipText={"Enter the password used to login to Sage for this company if it differs from the default password"}/>
					</div>

					<div className={`${styles.AddMemoWrapperWrapperItem}`}>
						<button  onClick={() => navigate(`/add-company/edit-payment-date`)}>
								<p className={`${styles.AddScheduleDate}`}>Add payment date *</p>
						</button>
					</div>

					{company.scheduleDates && company.scheduleDates.length > 0 &&
					<div className={`${styles.PaymentSchelduesWrapper}`}>
						<div className={`${styles.TitleWrapperWrapper}`}>
							<span className={`${styles.TitleWrapperWrapper_Title}`}>Payment schedules</span>
						</div>
					</div>
					}
					{company.scheduleDates && company.scheduleDates.length > 0 &&
						<div className={`row collapse`}>
							<div className={`columns small-12`}>
								<div className={`${styles.UsersWrapper}`} id="users">
									{company.scheduleDates &&
										company.scheduleDates.map((date, i) =>
											<p key={i} className={`${styles.ScheduleDate}`}>{date.displayText}</p>
										)
									}
								</div>
							</div>
						</div>
					}


					<div className={`${styles.ButtonWrapper}`}>
						<ButtonPrimary className={`${styles.ButtonPrimary}`} text="Add" type="submit" name="submit" onClick={onFormSubmit} />
					</div>

					{error &&
						<p className={`${styles.Error}`}>{error}</p>
					}
				</div>
			</div>
		}
	</div>
}
